import React from "react"
import { Slider, InputNumber } from "antd"

import styles from "./styles.module.scss"

const DistanceOption = ({ values, onChange }) => {
  const minValue = 0
  const maxValue = 50

  const inputFormatter = value => `${value}mi`

  return (
    <div className={styles.amountFilter}>
      <div className={styles.amountFilterLabel}>Distance:</div>
      <Slider
        range
        min={minValue}
        max={maxValue}
        tooltipVisible={false}
        marks={{ 0: "0mi", 50: "50mi" }}
        value={values}
        onChange={onChange}
      />
      <div className={styles.amountFilterInputs}>
        <InputNumber
          min={minValue}
          max={maxValue}
          value={values[0]}
          formatter={inputFormatter}
          onChange={value => onChange([value, values[1]])}
        />
        <div>-</div>
        <InputNumber
          min={minValue}
          max={maxValue}
          value={values[1]}
          formatter={inputFormatter}
          onChange={value => onChange([values[0], value])}
        />
      </div>
    </div>
  )
}

export default DistanceOption
