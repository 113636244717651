import { gql } from "@apollo/client"

export const getNeedTypes = gql`
  query {
    need_type {
      display_name
      need_type
      needs_aggregate(where: { is_available: { _eq: true } }) {
        aggregate {
          count(distinct: true, columns: family_id)
        }
      }
    }
  }
`
