import React, { useState, useRef, useMemo, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { GET_ORGS_FOR_CAROUSEL } from "../../queries/getDataForCarousel"
import Map, { Marker, Popup, GeolocateControl } from "react-map-gl"
import Loading from "../Loading"
import PartnerCarousel from "../PartnerCarousel"
import styles from "./styles.module.scss"
import { CardPartner } from "../CardPartner"
import Pin from "./pin"
import Geocoder from "../Geocoder"
import { CardPopup } from "../CardPopup"

const PartnerMap = ({
  partners,
  popupInfo,
  setPopupInfo,
  setCurrOrgId,
  setMapBounds,
  setMoving,
  isMobile,
  mapRef,
}) => {
  const pins = useMemo(
    () =>
      partners.map((partner, index) => {
        const city_coords = partner?.region?.location_coords?.coordinates
        return city_coords != null ? (
          <Marker
            key={`marker-${index}`}
            longitude={city_coords[0]}
            latitude={city_coords[1]}
            anchor="bottom"
            onClick={e => {
              e.originalEvent.stopPropagation()

              if (isMobile) {
                setPopupInfo({
                  latitude: partner.region.location_coords.coordinates[1],
                  longitude: partner.region.location_coords.coordinates[0],
                  org: partner.full_name,
                  location: partner.location_text,
                  desc: partner.description,
                  logo_url: partner.logo_url,
                })
              }

              mapRef.current.flyTo({
                center: [city_coords[0], city_coords[1]],
                zoom: 11.5,
                duration: 2500,
              })

              setCurrOrgId(partner.organization_id)
            }}
          >
            <div className={styles.marker}>
              <div className={styles.innerMarker} />
            </div>
          </Marker>
        ) : (
          <></>
        )
      }),
    []
  )

  return (
    <div className={styles.map}>
      <Map
        initialViewState={
          isMobile
            ? {
                latitude: 39.5,
                longitude: -120.0,
                zoom: 3.5,
                bearing: 0,
                pitch: 0,
              }
            : {
                latitude: 39.5,
                longitude: -120.0,
                zoom: 3.5,
                bearing: 0,
                pitch: 0,
              }
        }
        mapStyle="mapbox://styles/devnbshare/clrfvznl3009x01peg1ax3t4x"
        mapboxAccessToken={
          process.env.MAPBOX_ACCESS_TOKEN ||
          "pk.eyJ1IjoiZGV2bmJzaGFyZSIsImEiOiJjbHJmdTY2d3YwNWlhMmtteDZ5bTI0MW5pIn0.nRmWN1S31mjEX5grGJguKA"
        }
        maxBounds={
          isMobile
            ? [
                [-130.0, 24.0], // sw
                [-50.0, 50.0], // ne
              ]
            : [
                [-130.0, 24.0], // sw
                [-50.0, 50.0], // ne
              ]
        }
        onMoveEnd={evt => {
          const map = evt.target
          const bounds = map.getBounds()
          setMapBounds(bounds)
          setMoving(false)
        }}
        onMoveStart={evt => {
          setMoving(true)
        }}
        ref={mapRef}
      >
        {pins}
        <Geocoder
          mapboxAccessToken={
            process.env.MAPBOX_ACCESS_TOKEN ||
            "pk.eyJ1IjoiZGV2bmJzaGFyZSIsImEiOiJjbHJmdTY2d3YwNWlhMmtteDZ5bTI0MW5pIn0.nRmWN1S31mjEX5grGJguKA"
          }
          position="top-left"
        />
        <GeolocateControl position={"bottom-left"} />
        {popupInfo && (
          // overwriting styles of Popup container can be found in global index.scss: src/index.scss
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            className="partner-popup"
          >
            <CardPopup
              data={{
                org: popupInfo.org,
                location: popupInfo.location,
                desc: popupInfo.desc,
                logo_url: popupInfo.logo_url,
              }}
            />
          </Popup>
        )}
        <Pin />
      </Map>
    </div>
  )
}
const filterListByLocation = (d, bounds) => {
  const southEast = bounds.getSouthEast()
  const northWest = bounds.getNorthWest()
  const filteredList = d.filter(coord => {
    return (
      southEast.lat < coord.region.location_coords.coordinates[1] &&
      coord.region.location_coords.coordinates[1] < northWest.lat &&
      southEast.lng > coord.region.location_coords.coordinates[0] &&
      coord.region.location_coords.coordinates[0] > northWest.lng
    )
  })
  return filteredList
}
const Partners = () => {
  // Note - Not adding a filter to the query here for unhidden orgs
  // Because the same query is used on the "admin" part of the site
  // Although that is probably unused.
  const { data, loading } = useQuery(GET_ORGS_FOR_CAROUSEL)
  const [popupInfo, setPopupInfo] = useState(null)
  const [mapBounds, setMapBounds] = useState(null)
  const [moving, setMoving] = useState(false)
  const [isMobile, setIsMobile] = useState(null)
  const [currOrgId, setCurrOrgId] = useState(-1)
  const mapRef = useRef(null)
  const resizeListener = () => setIsMobile(window.innerWidth < 1024)

  useEffect(() => {
    setIsMobile(window && window.innerWidth < 1024)
    window && window.addEventListener("resize", resizeListener)
    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  if (loading) {
    return <Loading />
  }

  const organizations = data.organizations.filter(org => !org.hidden)
  var filterList = organizations
  if (moving) {
    filterList = []
  } else if (mapBounds) {
    filterList = filterListByLocation(organizations, mapBounds)
  }

  return (
    <section id="service-partners">
      <div className={`wrapper wrapper-straight`}>
        <h2 className="header">Meet Our Community Heroes</h2>
        <p />
        <p className="body-copy">
          We currently partner with <b>64</b> national and local nonprofits
          working on the ground with struggling Americans across <b>26</b>{" "}
          states. The frontline staff at these organizations - our community
          heroes - identify real families with verified needs that are at risk
          of slipping through the cracks and quickly bring those needs to
          NeighborShare's community of donors.
        </p>
        <div className={styles.mapContainer}>
          <PartnerMap
            partners={organizations}
            popupInfo={popupInfo}
            setPopupInfo={setPopupInfo}
            setCurrOrgId={setCurrOrgId}
            setMapBounds={setMapBounds}
            setMoving={setMoving}
            mapRef={mapRef}
            isMobile={isMobile}
          />
          {!isMobile && (
            <div className={styles.cardColumn}>
              {filterList.map((d, index) => (
                <CardPartner
                  isCurrent={d.organization_id === currOrgId}
                  key={index}
                  data={{
                    org: d.full_name,
                    location: d.location_text,
                    desc: d.description,
                    logo_url: d.logo_url,
                    coordinates: d.region?.location_coords?.coordinates,
                  }}
                  setCurrOrgId={() => setCurrOrgId(d.organization_id)}
                  mapRef={mapRef}
                />
              ))}
            </div>
          )}
        </div>
        {organizations.length > 0 && (
          <PartnerCarousel partners={organizations} />
        )}
      </div>
    </section>
  )
}

export default Partners
