import React from "react"
import ButtonCustom from "../ButtonCustom"

import styles from "./styles.module.scss"

const CardLast = ({
  title,
  description,
  handleClick,
  buttonLabel,
  buttonIcon,
  buttonIconClassName,
  buttonHierarchy = "secondary",
  buttonClassName,
}) => {
  return (
    <article className={styles.card}>
      <p className="small-header">{title}</p>
      <p className={`small-body-copy ${styles.description}`}>{description}</p>
      <ButtonCustom
        size="medium"
        className={buttonClassName}
        hierarchy={buttonHierarchy}
        handleClick={handleClick}
        role="button"
        icon={buttonIcon}
        iconClassName={buttonIconClassName}
      >
        {buttonLabel}
      </ButtonCustom>
    </article>
  )
}

export default CardLast
