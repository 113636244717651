import React, { useState, useEffect, useContext, useRef } from "react"
import { Popover, Checkbox, Radio } from "antd"
import { from, useQuery } from "@apollo/client"
import { getNeedTypes } from "../../queries/needTypes"
import { get_organizations } from "../../queries/getOrganizations"
import { GET_LOCATION_NAMES_BY_REGION } from "../../queries/getLocationNames"

import DropdownCustom from "../DropdownCustom"
import PriceOption from "../PriceOption"
import DistanceOption from "../DistanceOption"

import styles from "./styles.module.scss"
import Ctx from "../../context"
import { formatMoney } from "../../utils/format"
import ButtonCustom from "../ButtonCustom"
import { fairfieldCountyLocations } from "../../../functions/utils/calc"

const FamiliesFilter = ({ sponsor = null, organization = null }) => {
  const { data } = useQuery(getNeedTypes)
  const { filters, allFamilies } = useContext(Ctx)
  let options
  if (filters.filters.region) {
    options = {
      variables: {
        region_id: filters.filters.region,
      },
    }
  }

  let sponsor_unhidden_organizations = sponsor
    ? JSON.parse(sponsor.unhidden_organizations)
    : null

  const unhiddenOrganizations = organization
    ? [organization.organization_id]
    : sponsor_unhidden_organizations || []

  const unhiddenFamilies = allFamilies.value.filter(
    family =>
      !family.organization.hidden ||
      unhiddenOrganizations.includes(family.organization.organization_id)
  )

  const organizationsWithFamily = new Set(
    unhiddenFamilies.map(family => family.organization.organization_id)
  )

  const sponsor_needs = sponsor
    ? JSON.parse(sponsor.filter_criteria_needs_type)
    : null
  let sponsor_locations = sponsor
    ? JSON.parse(sponsor.filter_criteria_geo)
    : null

  let sponsor_locations_text =
    sponsor_locations != null
      ? sponsor_locations.map(location => location.split(",")[0]).join(", ")
      : ""

  let isFairfield = false
  if (sponsor_locations != null)
    isFairfield = sponsor_locations.includes("Fairfield County, CT")

  if (isFairfield) {
    sponsor_locations.pop("Fairfield County, CT")
    sponsor_locations.push.apply(sponsor_locations, fairfieldCountyLocations)
  }

  const organizationText = organization ? organization.name : ""

  const { data: location } = useQuery(
    GET_LOCATION_NAMES_BY_REGION(Boolean(filters.filters.region)),
    options
  )

  const thecitylist = unhiddenFamilies.map(family => {
    return family.location_text
  })
  const uniqueCities = !sponsor_locations
    ? [...new Set(thecitylist)]
    : sponsor_locations

  const [selectedDistance, setSelectedDistance] = useState([])
  const [cityTextFilter, setCityTextFilter] = useState("")

  const formatTextCase = word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }

  const needTypes = !sponsor
    ? ((data && data.need_type) || []).map(d => ({
        value: d.need_type,
        text: d.display_name.charAt(0).toUpperCase() + d.display_name.slice(1),
        count: d.needs_aggregate.aggregate.count,
      }))
    : ((sponsor && sponsor_needs) || []).map(need => {
        return {
          value: need,
          text: formatTextCase(need),
          count: 1, //family.needs_aggregate.aggregate.count,
        }
      })

  const [organizationTextFilter, setOrganizationTextFilter] = useState("")

  const [selectedNeedTypes, setSelectedNeedTypes] = useState([])

  const handleNeedTypeChange = value => {
    const selectedValues = selectedNeedTypes.includes(value)
      ? selectedNeedTypes.filter(item => item !== value)
      : [...selectedNeedTypes, value]
    setSelectedNeedTypes(selectedValues)
    onNeedTypeSelected(selectedValues)
  }

  const distances = uniqueCities.map(city => ({
    value: city,
    text: city,
    city: city.split(",")[0] != undefined ? city.split(",")[0].trim() : "",
    state: city.split(",")[1] != undefined ? city.split(",")[1].trim() : "",
  }))

  const { data: organizationsData } = useQuery(get_organizations)

  const organizations = (organizationsData?.organizations || []).map(org => ({
    value: org.organization_id,
    text: org.name,
  }))

  const organizationItems = [
    {
      text: "Any nonprofit partner",
      value: "Any nonprofit partner",
      selected: filters.filters.organizations.length === 0,
    },
    ...organizations
      .filter(org => {
        const lowerOrgValue = org.text.toLowerCase()
        const lowerOrgText = organizationTextFilter.toLowerCase()
        return !!lowerOrgValue.includes(lowerOrgText)
      })
      .filter(org => {
        return organizationsWithFamily.has(org.organization_id)
      })
      .map(org => ({
        ...org,
        selected: filters.filters.organizations == org.value,
      })),
  ]

  let selectedOrg = organizations.find(
    org => org.value === filters.filters.organizations[0]
  )
  if (selectedOrg) selectedOrg = selectedOrg.text

  const selectedOrganizationText =
    filters.filters.organizations.length > 0
      ? selectedOrg
      : "Any nonprofit partner"

  const onNeedTypeSelected = selectedTypes => {
    filters.setNeeds(selectedTypes)
  }

  const onDistanceSelected = distance => {
    setSelectedDistance(distance)
    filters.setLocations(distance)
  }
  const onAmountFromToChange = values => {
    filters.setPriceRange(values[0], values[1])
  }
  const onDistanceRangeChange = values => {
    filters.setDistanceRange(values[0], values[1])
  }

  useEffect(() => {
    if (sponsor) {
      filters.setNeedsAndLocations(sponsor_needs || [], sponsor_locations || [])
    }
  }, [sponsor])

  useEffect(() => {
    if (organization) {
      filters.setOrganizations([organization.organization_id])
    }
  }, [organization])

  const onOrganizationSelected = organization => {
    filters.setOrganizations(organization)
  }

  const priceOption = (
    <PriceOption
      values={[filters.filters.priceRange[0], filters.filters.priceRange[1]]}
      onChange={onAmountFromToChange}
    />
  )

  const distanceOption = (
    <DistanceOption
      values={[
        filters.filters.distanceRange[0],
        filters.filters.distanceRange[1],
      ]}
      onChange={onDistanceRangeChange}
    />
  )

  const selectedCityText =
    selectedDistance.length > 0 ? selectedDistance[0] : "Anywhere"

  const cityItems = [
    {
      text: "Anywhere",
      value: "Anywhere",
      selected: selectedDistance.length === 0,
    },
    ...distances
      .filter(distance => {
        const lowerDistanceValue = distance.value.toLowerCase()
        const lowerCityText = cityTextFilter.toLowerCase()
        if (lowerDistanceValue.includes(lowerCityText)) return true
        return false
      })
      .sort((a, b) => a.state.localeCompare(b.state))
      .map(distance => ({
        ...distance,
        selected: filters.filters.locations.includes(distance.value),
      })),
  ]

  if (sponsor) {
    const familiesIntro =
      sponsor.landing_page_copy?.familiesIntro ||
      "Meet household needs in partnership with"
    return (
      <div className={styles.familiesFilterContainer}>
        <div className={styles.helpTitleRow}>
          <p className={styles.helpTitle}>{familiesIntro}</p>
          <img
            src={sponsor.logo_url}
            alt={`${sponsor.name} Logo`}
            className={styles.sponsorLogo}
          />
        </div>
        <div className={styles.tagsRow}>
          <div className={styles.tagsRowInner}>
            {needTypes.map(
              (need, i) =>
                need.count > 0 && (
                  <HelpOptionTag
                    key={i}
                    selected={filters.filters.needs.includes(need.value)}
                    styles={styles}
                    need={need}
                    clickEvent={handleNeedTypeChange}
                    items={needTypes.map(needType => ({
                      ...needType,
                    }))}
                  />
                )
            )}
          </div>
        </div>
        {sponsor_locations_text && (
          <div className={styles.cityFilterRow}>
            Needs in{" "}
            {<b className={styles.cityText}>{sponsor_locations_text}</b>}
          </div>
        )}
        <div className={styles.cityFilterRow}>
          <OrganizationSelector
            styles={styles}
            text={selectedOrganizationText}
            items={organizationItems}
            onSelect={onOrganizationSelected}
            onOrganizationFilter={setOrganizationTextFilter}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.familiesFilterContainer}>
        <div className={styles.helpTitleRow}>
          <p className={styles.helpTitle}>Meet Households in Need</p>
        </div>
        <div className={styles.tagsRow}>
          <div className={styles.tagsRowInner}>
            {needTypes.map(
              (need, i) =>
                need.count > 0 && (
                  <HelpOptionTag
                    key={i}
                    selected={filters.filters.needs.includes(need.value)}
                    styles={styles}
                    need={need}
                    clickEvent={handleNeedTypeChange}
                    items={needTypes.map(needType => ({
                      ...needType,
                    }))}
                  />
                )
            )}
          </div>
        </div>
        <div className={styles.cityFilterRow}>
          <CitySelector
            styles={styles}
            text={selectedCityText}
            items={cityItems}
            onSelect={onDistanceSelected}
            onCityFilter={setCityTextFilter}
          />
        </div>
        {organization != null ? (
          <div className={styles.cityFilterRow}>
            Referred by <b className={styles.cityText}>{organizationText}</b>
          </div>
        ) : (
          <div className={styles.cityFilterRow}>
            <OrganizationSelector
              styles={styles}
              text={selectedOrganizationText}
              items={organizationItems}
              onSelect={onOrganizationSelected}
              onOrganizationFilter={setOrganizationTextFilter}
            />
          </div>
        )}
      </div>
    )
  }
}

const CitySelector = ({ styles, text, items, onSelect, onCityFilter }) => {
  const [cityMenuOpen, setCityMenuOpen] = useState(false)
  const inputRef = useRef(null)

  const toggleItemSelection = ({ value, selected }) => {
    if (value === "Anywhere") {
      onSelect([])
    } else {
      const selectedValues = items
        .filter(item => item.selected)
        .map(item => item.value)
      const newValues = [value]

      onSelect(newValues)
    }

    setCityMenuOpen(false)
  }

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }

  useEffect(() => {
    if (cityMenuOpen) {
      setTimeout(() => {
        focusInput()
      }, 100)
    }
  }, [inputRef.current, cityMenuOpen])

  const popoverContent = (
    <div className={styles.locationFilterPopover}>
      <div className={styles.locationFilterPopoverInner}>
        <p className={styles.locationFilterTitle}>Select Location</p>

        <input
          className={styles.locationFilterInput}
          key="123456xxx"
          ref={inputRef}
          type="text"
          placeholder="Enter city"
          onChange={e => onCityFilter(e.target.value)}
        />

        <p className={styles.locationFilterSubtitle}>Or select a city</p>
      </div>
      <div className={styles.locationFilterDropdownItemWrapper}>
        {items.map((item, index) => {
          return (
            <button
              key={index}
              className={`${styles.locationFilterDropdownItem} ${
                item.selected ? "location-filter-item-selected" : ""
              }`}
              onClick={() => toggleItemSelection(item)}
            >
              {item.text}
            </button>
          )
        })}
      </div>
    </div>
  )

  const needsText = text === "Anywhere" ? "Needs from" : "Needs in"

  return (
    <>
      <Popover
        trigger="click"
        placement="bottom"
        visible={cityMenuOpen}
        content={popoverContent}
        onVisibleChange={setCityMenuOpen}
      >
        <div className={styles.selectCityTextRow}>
          {needsText} <span className={styles.selectCityText}>{text}</span>
        </div>
      </Popover>
    </>
  )
}

const OrganizationSelector = ({
  styles,
  text,
  items,
  onSelect,
  onOrganizationFilter,
}) => {
  const [organizationMenuOpen, setOrganizationMenuOpen] = useState(false)
  const inputRef = useRef(null)

  const toggleItemSelection = ({ value, selected }) => {
    if (value == "Any nonprofit partner") {
      onSelect([])
    } else {
      const selectedValues = items
        .filter(item => item.selected)
        .map(item => item.value)
      const newValues = [value]
      onSelect(newValues)
    }
    setOrganizationMenuOpen(false)
  }

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }

  useEffect(() => {
    if (organizationMenuOpen) {
      setTimeout(() => {
        focusInput()
      }, 100)
    }
  }, [inputRef.current, organizationMenuOpen])

  const popoverContent = (
    <div className={styles.locationFilterPopover}>
      <div className={styles.locationFilterPopoverInner}>
        <p className={styles.locationFilterTitle}>Select Organization</p>
        <input
          className={styles.locationFilterInput}
          key="orgInput"
          ref={inputRef}
          type="text"
          placeholder="Enter organization"
          onChange={e => onOrganizationFilter(e.target.value)}
        />
        <p className={styles.locationFilterSubtitle}>
          Or select an organization
        </p>
      </div>
      <div className={styles.locationFilterDropdownItemWrapper}>
        {items.map((item, index) => (
          <button
            key={index}
            className={`${styles.locationFilterDropdownItem} ${
              item.selected ? "location-filter-item-selected" : ""
            }`}
            onClick={() => toggleItemSelection(item)}
          >
            {item.text}
          </button>
        ))}
      </div>
    </div>
  )

  const needsText =
    text === "Any nonprofit partner" ? "Referred by" : "Referred by"

  return (
    <Popover
      trigger="click"
      placement="bottom"
      visible={organizationMenuOpen}
      content={popoverContent}
      onVisibleChange={setOrganizationMenuOpen}
    >
      <div className={styles.selectCityTextRow}>
        {needsText} <span className={styles.selectCityText}>{text}</span>
      </div>
    </Popover>
  )
}

const HelpOptionTag = ({ selected, styles, need, clickEvent, items }) => {
  const toggleItemSelection = value => {
    const selectedValues = items
      .filter(item => item.selected)
      .map(item => item.value)
    const newValues = selected
      ? selectedValues.filter(selectedValue => selectedValue !== value)
      : [...selectedValues, value]

    clickEvent(value)
  }

  let svgComponent

  switch (need.value) {
    case "HOUSING": {
      svgComponent = <img alt="Housing" src={"needsFilterIcons/Housing.svg"} />
      break
    }
    case "BILLS": {
      svgComponent = <img alt="Bills" src={"needsFilterIcons/Bills.svg"} />
      break
    }
    case "CHILDCARE": {
      svgComponent = (
        <img alt="Childcare" src={"needsFilterIcons/Childcare.svg"} />
      )
      break
    }
    case "EDUCATION": {
      svgComponent = (
        <img alt="Education" src={"needsFilterIcons/Education.svg"} />
      )
      break
    }
    case "FURNISHINGS": {
      svgComponent = (
        <img alt="Furnishings" src={"needsFilterIcons/Furnishings.svg"} />
      )
      break
    }
    case "MISC": {
      svgComponent = <img alt="Misc" src={"needsFilterIcons/Misc.svg"} />
      break
    }
    case "TRANSPORT": {
      svgComponent = (
        <img alt="Transportation" src={"needsFilterIcons/Transportation.svg"} />
      )
      break
    }
    case "GROCERIES": {
      svgComponent = (
        <img alt="Groceries" src={"needsFilterIcons/Groceries.svg"} />
      )
      break
    }
    case "MEDICAL": {
      svgComponent = <img alt="Medical" src={"needsFilterIcons/Medical.svg"} />
      break
    }
    case "CLOTHING": {
      svgComponent = (
        <img alt="Clothing" src={"needsFilterIcons/Clothing.svg"} />
      )
      break
    }
    default: {
      svgComponent = <img alt="Misc" src={"needsFilterIcons/Misc.svg"} />
      break
    }
  }

  let selectedStyle = {}
  if (selected) {
    selectedStyle.background = "var(--color-coral)"
  } else {
    selectedStyle = {}
  }

  return (
    <div
      className={styles.helpOptionTag}
      style={selectedStyle}
      onClick={() => toggleItemSelection(need.value)}
    >
      <div className={styles.helpOptionTagSvg}>{svgComponent}</div>
      <p className={styles.helpOptionTagTitle}>{need.text}</p>
    </div>
  )
}

export default FamiliesFilter
