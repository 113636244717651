import React from "react"
import PropTypes from "prop-types"
import { DONATION_TYPES } from "../../constants/donation-types"

import styles from "./styles.module.scss"

const CardPopup = ({ data, isClosed = false }) => {
  const { org, location, desc, logo_url } = data
  const svgComponent = <img alt="Misc" src={"/needsIcons/Misc.svg"} />

  // Conditionally define what component to render based on logo_url
  const imageComponent = logo_url ? (
    <img
      alt={`${org} logo`}
      src={logo_url}
      style={{
        width: "auto",
        height: "5rem",
        maxWidth: "7rem",
      }}
    />
  ) : (
    svgComponent
  )
  const description = desc
    ? desc
    : "Our partner " + org + " does wonderful work in " + location

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardInner}>
        <div className={styles.cardTitleRow}>
          <p className={styles.cardTitle}>{org}</p>
          <div className={styles.cardTitleRowSvg}>{imageComponent}</div>
        </div>

        <div className={styles.cardSubtitleRow}>
          <p className={styles.cardSubtitle}>{description}</p>
        </div>
      </div>

      <div className={styles.cardFooter}>
        <p className={styles.cardFooterText}>{location}</p>
      </div>
    </div>
  )
}

CardPopup.defaultProps = {
  data: {
    icon: DONATION_TYPES.OTHER,
  },
}

// org, city, state, desc
CardPopup.propTypes = {
  data: PropTypes.shape({
    org: PropTypes.string,
    location: PropTypes.string,
    desc: PropTypes.string,
    logo_url: PropTypes.string,
  }),
}

export { CardPopup }
