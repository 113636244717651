import React from "react"
import { useQuery } from "@apollo/client"
import { GET_CORPS_FOR_CAROUSEL } from "../../queries/getDataForCarousel"

import Loading from "../Loading"
import CorporateCarousel from "../CorporateCarousel"

const Partners = () => {
  const { data, loading, error } = useQuery(GET_CORPS_FOR_CAROUSEL)

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
  }

  return (
    <section id="corporates">
      <div className={`wrapper wrapper-straight`}>
        <h2 className="header">Join Our Neighborhood</h2>
        <p />
        <p className="body-copy">
          We subscribe to the mindset that it takes a village - it's just that
          VillageShare didn't have quite the same ring to it! In addition to our
          generous individual donors, we are proud to partner with organizations
          who are equally committed to supporting their neighbors.
        </p>
        <br />
        <h2 className="small-header">
          Companies, foundations, and brands can partner with NeighborShare to
          deliver direct and tangible impact within communities.{" "}
        </h2>
        <p />
        <p className="body-copy">
          From sponsoring your local backyard or a need category you are
          passionate about, to empowering your employees or customers to help
          neighbors in need—together we can amplify the impact of your
          charitable dollars through a hyper-local, tech-enabled, and direct
          way. <a href="mailto:info@nbshare.org">Contact us</a> if you're
          interested in exploring joining our incredible neighborhood.
        </p>
        <CorporateCarousel corporates={data.corporates} />
      </div>
    </section>
  )
}

export default Partners
