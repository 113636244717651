import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import styles from "./styles.module.scss"

const sizes = {
  desktop: {
    breakpoint: { max: 10000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Card = ({ name, logo_url }) => (
  <article className={styles.card}>
    <div>
      <img
        src={logo_url}
        className={styles.logo}
        alt={`Logo for the partner: ${name}`}
      />
    </div>
  </article>
)

const CorporateCarousel = ({ corporates }) => (
  <Carousel
    autoPlay
    infinite
    showDots
    swipeable
    arrows={false}
    autoPlaySpeed={5000}
    responsive={sizes}
    className={styles.carousel}
  >
    {corporates.map(({ logo_url, name }) => (
      <Card key={name} name={name} logo_url={logo_url} />
    ))}
  </Carousel>
)

export default CorporateCarousel
