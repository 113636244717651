import { gql } from "@apollo/client"

export const GET_LOCATION_NAMES_BY_REGION = hasRegionID => {
  if (hasRegionID) {
    return gql`
      query getLocationNames($region_id: uuid!) {
        families(
          distinct_on: location_text
          where: { organization: { region_id: { _eq: $region_id } } }
        ) {
          location_text
        }
      }
    `
  } else {
    return gql`
      query getLocationNames {
        families(distinct_on: location_text) {
          location_text
        }
      }
    `
  }
}
