import React from "react"
import PropTypes from "prop-types"
import { DONATION_TYPES } from "../../constants/donation-types"

import styles from "./styles.module.scss"

const CardPartner = ({
  data,
  isClosed = false,
  isCurrent,
  mapRef,
  setCurrOrgId,
}) => {
  const { org, location, desc, logo_url } = data
  const svgComponent = <img alt="Misc" src={"/needsIcons/Misc.svg"} />

  // Conditionally define what component to render based on logo_url
  const imageComponent = logo_url ? (
    <img
      alt={`${org} logo`}
      src={logo_url}
      style={{
        width: "auto",
        height: "6.5rem",
        maxWidth: "10rem",
        marginLeft: "1rem",
        marginBottom: "1rem",
      }}
    />
  ) : (
    svgComponent
  )
  const description = desc
    ? desc
    : "Our partner " + org + " does wonderful work in " + location

  return (
    <div
      className={isCurrent ? styles.currentCard : styles.cardContainer}
      onClick={() => {
        setCurrOrgId()
        mapRef.current.flyTo({
          center: [data.coordinates[0], data.coordinates[1]],
          zoom: 11.5,
          duration: 2500,
        })
      }}
    >
      <div className={styles.cardInner}>
        <div className={styles.cardTitleRow}>
          <p className={styles.cardTitle}>{org}</p>
          <div className={styles.cardTitleRowSvg}>{imageComponent}</div>
        </div>

        <div className={styles.cardSubtitleRow}>
          <p className={styles.cardSubtitle}>{description}</p>
        </div>
      </div>

      <div className={styles.cardFooter}>
        <p className={styles.cardFooterText}>{location}</p>
      </div>
    </div>
  )
}

CardPartner.defaultProps = {
  data: {
    icon: DONATION_TYPES.OTHER,
  },
}

// org, city, state, desc
CardPartner.propTypes = {
  data: PropTypes.shape({
    org: PropTypes.string,
    location: PropTypes.string,
    desc: PropTypes.string,
    logo_url: PropTypes.string,
  }),
}

export { CardPartner }
