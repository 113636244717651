import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import styles from "./styles.module.scss"

const sizes = {
  desktop: {
    breakpoint: { max: 10000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Card = ({ full_name, logo_url, location }) => (
  <article className={styles.card}>
    <div>
      <img
        src={logo_url}
        className={styles.logo}
        alt={`Logo for the partner: ${full_name}`}
      />
      <h3 className="tiny-body-copy">{location}</h3>
    </div>
  </article>
)

const PartnerCarousel = ({ partners }) => (
  <Carousel
    autoPlay
    infinite
    showDots
    swipeable
    arrows={false}
    autoPlaySpeed={5000}
    responsive={sizes}
    className={styles.carousel}
  >
    {partners
      .filter(p => !!p.logo_url)
      .map(({ logo_url, full_name, location_text }) => (
        <Card
          key={full_name}
          logo_url={logo_url}
          full_name={full_name}
          location={location_text}
        />
      ))}
  </Carousel>
)

export default PartnerCarousel
