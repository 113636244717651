import React from "react"
import styles from "./styles.module.scss"
import "./styles.css"

const Subscribe = () => {
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className="klaviyo-form-XLJRyb"></div>
      </div>
    </section>
  )
}

export default Subscribe
