import React from "react"

import styles from "./styles.module.scss"

const mailtoEmail = "info@nbshare.org"

const EmailSection = () => (
  <section className={styles.intro}>
    <p>
      Do you represent a nonprofit?
      <span> </span>
      <a
        href={`mailto:${mailtoEmail}?subject=Non-Profit interested in joining NeighborShare`}
        target="_blank"
      >
        Learn how to join NeighborShare
      </a>
    </p>
  </section>
)

export default EmailSection
